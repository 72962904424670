import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useDevicesList() {
  // Use toast
  const toast = useToast()

  const refDeviceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true, label: 'ID' },
    { key: 'name', sortable: true, label: 'Cód/Nome' },
    { key: 'model', sortable: true, label: 'Modelo' },
    { key: 'probes', sortable: true, label: 'N°Sondas' },
    { key: 'firmware', sortable: true, label: 'Firmware' },
    { key: 'deviceKey', sortable: true, label: 'Chave' },
    { key: 'isActive', sortable: true, label: 'Ativo' },
    { key: 'assetId', sortable: true, label: 'Associado?' },
    { key: 'createdAt', sortable: true, label: 'Último Dado' },
    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    { key: 'status', sortable: true, label: 'Status' },
    { key: 'actions', sortable: true, label: 'Ações' },
  ]
  const perPage = ref(10)
  const totalDevices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refDeviceListTable.value ? refDeviceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDevices.value,
    }
  })

  const refetchData = () => {
    refDeviceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchDevices = (ctx, callback) => {
    store
      .dispatch('app-device/fetchDevices', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const devices = response.data
        const total = Object.keys(response.data).length
        callback(devices)
        totalDevices.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar lista de dispositivos',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveDeviceModelVariant = model => {
    if (model === 'ReefTrack v1') return 'primary'
    if (model === 'CoolMon Mini v1') return 'warning'
    // if (model === 'maintainer') return 'success'
    // if (model === 'v1.11-2022') return 'info'
    // if (model === 'admin') return 'danger'
    return 'primary'
  }

  const resolveDeviceStatusIcon = role => {
    if (role === 'StarCool') return 'AlertTriangleIcon'
    if (role === 'Daikin') return 'CloudOffIcon'
    if (role === 'Carrier') return 'CheckCircleIcon'
    // if (role === '4') return 'Edit2Icon'
    // if (role === '5') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveDeviceIsActiveVariant = status => {
    if (status === null) return 'warning'
    if (status === true) return 'success'
    if (status === false) return 'secondary'
    return 'primary'
  }

  return {
    fetchDevices,
    tableColumns,
    perPage,
    currentPage,
    totalDevices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDeviceListTable,

    resolveDeviceModelVariant,
    resolveDeviceStatusIcon,
    resolveDeviceIsActiveVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
