<template>
  <div v-if="devices">

    <b-row class="match-height">
      <b-col
        v-for="item in devices"
        :key="item.icon"
        xl="3"
        sm="6"
        md="6"
      >

        <!-- Card body -->
        <b-card @click="$router.push('/monitor/details')" class="cursor-pointer">

          <b-row align-v="center" >
            <b-col class="ml-0 col-7" >
              <h4 class="mb-0">
                {{ item.name }}
              </h4>
              <p class="text-sm text-muted mb-0">
                {{ item.brand }}
              </p>
            </b-col>

            <b-col class="col-5 pr-3 d-flex justify-content-end">
                <b-row>
              <h1 class="display-3">
                {{ item.id }}
              </h1>
              <h5 class="mt-1">°C</h5>
              </b-row>
            </b-col>

          </b-row>

          <!-- chart -->
          <vue-apex-charts
            height="35"
            :options="statisticsProfit.chartOptions"
            :series="statisticsProfit.series"
          />

          <b-row align-v="center">
            <b-col class="ml--2">
              <feather-icon
                icon="ActivityIcon"
                class="text-secondary"
                size="25"
              />
            </b-col>
            <b-col class="ml--2">
              <feather-icon
                icon="AlertCircleIcon"
                class="text-danger"
                size="25"
              />
            </b-col>
            <b-col class="ml--2">
              <feather-icon
                icon="PowerIcon"
                class="text-secondary"
                size="25"
              />
            </b-col>
            <b-col class="ml--2">
              <feather-icon
                icon="WifiIcon"
                class="text-secondary"
                size="25"
              />
            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BFormInput, BButton, BTable, BMedia, BMediaBody, BMediaAside, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { $themeColors } from '@themeConfig'
import { ref, onUnmounted } from '@vue/composition-api'

import monitorList from './monitorList'
import monitorStoreModule from './monitorStoreModule'

const $trackBgColor = '#EBEBEB'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMediaBody,
    BMediaAside,

    VueApexCharts,

    vSelect,
  },
  props: {
    data: {
      type: Array,
      default: () => [
        {
          icon: 'CloudOffIcon',
          color: 'light-primary',
          title: '-15,2ºC',
          subtitle: 'Reefer 1',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '7,2ºC',
          subtitle: 'Reefer 2',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '-8ºC',
          subtitle: 'Reefer 3',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '-12ºC',
          subtitle: 'Reefer 4',
          customClass: '',
        },
      ],
    },
  },
  setup() {
    const MONITOR_APP_STORE_MODULE_NAME = 'app-monitor'
    // console.log(fetchUsers)

    // Register module
    if (!store.hasModule(MONITOR_APP_STORE_MODULE_NAME)) store.registerModule(MONITOR_APP_STORE_MODULE_NAME, monitorStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MONITOR_APP_STORE_MODULE_NAME)) store.unregisterModule(MONITOR_APP_STORE_MODULE_NAME)
    })
    
    const devices = ref(null) 
    
    store.dispatch('app-monitor/fetchDevices')
      .then(response => { devices.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          devices.value = undefined
        }
      })

    const modelOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const isActiveOptions = [
      { label: 'Pendente', value: 'null' },
      { label: 'Ativo', value: 'true' },
      { label: 'Inativo', value: 'false' },
    ]

    const {
      fetchDevices,
      tableColumns,
      perPage,
      currentPage,
      totalMonitor,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMonitorListTable,
      refetchData,

      // UI
      resolveMonitorModelVariant,
      resolveMonitorStatusIcon,
      resolveMonitorIsActiveVariant,

    } = monitorList()

    return {
      devices,
      fetchDevices,
      tableColumns,
      perPage,
      currentPage,
      totalMonitor,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMonitorListTable,
      refetchData,

      // UI
      resolveMonitorModelVariant,
      resolveMonitorStatusIcon,
      resolveMonitorIsActiveVariant,

      modelOptions,
      planOptions,
      isActiveOptions,

    }
  },
  data() {
    return {
      statisticsProfit: {
        series: [
          {
            data: [-10, -12, -8, -15, -14, -8, -14],
          },
        ],
        chartOptions: {
          chart: {

            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -20,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
            curve: 'smooth',
          },
          colors: [$themeColors.info],
          markers: {
            size: 2,
            colors: $themeColors.info,
            strokeColors: $themeColors.info,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: '#ffffff',
                strokeColor: $themeColors.info,
                size: 5,
              },
            ],
            shape: 'circle',
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                fontSize: '0px',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.card:hover{
     transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
