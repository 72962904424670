import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDevices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://api.joule.work/API/Devices', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDevice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`https://api.joule.work/API/Devices/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addDevice(ctx, deviceData) {
      return new Promise((resolve, reject) => {
        axios
          .post('https://api.joule.work/API/Devices/', { user: deviceData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
